import React from 'react';
import { Link } from 'gatsby';
import Layout from './layout';

export default function LessonLayout({ title, styles = '', children }) {
  return (
    <Layout title={title} styles={styles}>
      <div className="lesson-layout">
        <div className="lesson-menu">
          <Link to="/lessons/preschool/" partiallyActive={true} activeClassName="active">
            Preschool
          </Link>
          <Link to="/lessons/children/" partiallyActive={true} activeClassName="active">
            Children
          </Link>
          <Link to="/lessons/adult/" partiallyActive={true} activeClassName="active">
            Adult
          </Link>
        </div>
        <div className="lesson-content">{children}</div>
      </div>
    </Layout>
  );
}
