import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import LessonLayout from '../../components/LessonLayout';
import LessonList from '../../components/LessonList';

const AdultLessons = () => {
  const lessons = [
    {
      name: 'Adult Group Lessons',
      image: (
        <StaticImage
          src="../../images/lessons/adult.webp"
          alt="Adult Group Lessons"
          height={300}
          width={300}
          transformOptions={{
            cropFocus: 'center',
            fit: 'cover',
          }}
        />
      ),
      features: [
        {
          name: 'Students aged 13+',
        },
        {
          name: 'Maximum of 4 Students Per Group',
        },
        {
          name: '30 Minute Lesson',
        },
        {
          name: 'Instructor Supporting in the Water',
        },
      ],
      locations: [
        {
          name: "St Paul's High",
        },
        {
          name: 'Blantyre',
        },
      ],
    },
    {
      name: 'Adult Private 1 - 1',
      image: (
        <StaticImage
          src="../../images/lessons/adult.webp"
          alt="Adult Private 1 - 1"
          height={300}
          width={300}
          transformOptions={{
            cropFocus: 'center',
            fit: 'cover',
          }}
        />
      ),
      features: [
        {
          name: 'Students aged 13+',
        },
        {
          name: '1 Student Per Lesson',
        },
        {
          name: '30 Minute Lesson',
        },
        {
          name: 'Instructor Supporting in the Water',
        },
      ],
      locations: [
        {
          name: "St Paul's High",
        },
        {
          name: 'Blantyre',
        },
        {
          name: 'Holyrood Secondary',
        },
      ],
    },
    {
      name: 'Adult Private 1 - 2',
      image: (
        <StaticImage
          src="../../images/lessons/adult.webp"
          alt="Adult Private 1 - 1"
          height={300}
          width={300}
          transformOptions={{
            cropFocus: 'center',
            fit: 'cover',
          }}
        />
      ),
      features: [
        {
          name: 'Students aged 13+',
        },
        {
          name: '2 Students Per Lesson',
        },
        {
          name: '30 Minute Lesson',
        },
        {
          name: 'Instructor Supporting in the Water',
        },
      ],
      locations: [
        {
          name: "St Paul's High",
        },
        {
          name: 'Blantyre',
        },
        {
          name: 'Holyrood Secondary',
        },
      ],
    },
  ];

  return (
    <LessonLayout styles="lessons" title="Children's Swimming Lessons">
      <LessonList lessons={lessons} />
    </LessonLayout>
  );
};

export default AdultLessons;
